import React, { useRef, useState } from "react";
import emailjs from 'emailjs-com';
import tw from "twin.macro";
import styled from "styled-components";
import { SectionHeading } from "components/misc/Headings.js";
import { PrimaryButton as PrimaryButtonBase } from "components/misc/Buttons.js";
import EmailIllustrationSrc from "images/email-illustration.svg";

const Container = tw.div`relative`;
const TwoColumn = tw.div`flex flex-col md:flex-row justify-between max-w-screen-xl mx-auto py-20 md:py-24`;
const Column = tw.div`w-full max-w-md mx-auto md:max-w-none md:mx-0`;
const ImageColumn = tw(Column)`md:w-5/12 flex-shrink-0 h-80 md:h-auto`;
const TextColumn = styled(Column)(props => [
  tw`md:w-7/12 mt-16 md:mt-0`,
  props.textOnLeft ? tw`md:mr-12 lg:mr-16 md:order-first` : tw`md:ml-12 lg:ml-16 md:order-last`
]);

const Image = styled.div(props => [
  `background-image: url("${props.imageSrc}");`,
  tw`rounded bg-contain bg-no-repeat bg-center h-full`,
]);
const TextContent = tw.div`lg:py-8 text-center md:text-left`;

const Heading = tw(SectionHeading)`mt-4 font-black text-left text-3xl sm:text-4xl lg:text-5xl text-center md:text-left leading-tight`;
// const Description = tw.p`mt-4 text-center md:text-left text-sm md:text-base lg:text-lg font-medium leading-relaxed text-secondary-100`

const Form = tw.form`mt-8 md:mt-10 text-sm flex flex-col max-w-sm mx-auto md:mx-0`
const Input = tw.input`mt-6 first:mt-0 border-b-2 py-3 focus:outline-none font-medium transition duration-300 hocus:border-primary-500`
const Textarea = styled(Input).attrs({as: "textarea"})`
  ${tw`h-24`}
`

const SubmitButton = tw(PrimaryButtonBase)`inline-block mt-8`

const ContactUs = ({
  heading = <>Feel free to <span tw="text-primary-500">get in touch</span><wbr/> with us.</>,
  // description = "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.",
  submitButtonText = "Send",
  textOnLeft = true,
}) => {

  const [email, setEmail] = useState("");
  const [name, setName] = useState("");
  const [subject, setSubject] = useState("");
  const [phone, setPhone] = useState("");
  const [message, setMessage] = useState("");

  const form = useRef();

  const handleChange = (event) => {
    event.target.name==="name" 
    ? setName(event.target.value)
    : event.target.name==="email" 
    ? setEmail(event.target.value)
    : event.target.name==="subject" 
    ? setSubject(event.target.value)
    : event.target.name==="phone" 
    ? setPhone(event.target.value)
    : event.target.name==="message" 
    ? setMessage(event.target.value)
    : console.log("error")
  };

  const sendEmail = (e) => {
    e.preventDefault();

    emailjs.sendForm('service_htug5hh', 'template_8riuv08', form.current, 'user_t0d2NpLxUuCT5WfFJXHIw')
      .then((result) => {
          console.log(result.text);
          alert("Mail sent successfully!");
          setEmail("");
          setName("");
          setSubject("");
          setPhone("");
          setMessage("");
      }, (error) => {
          console.log(error.text);
          alert("Your Mail does not sent, please try again !!!") 
      });
  };

  return (
    <Container>
      <TwoColumn>
        <ImageColumn>
          <Image imageSrc={EmailIllustrationSrc} />
        </ImageColumn>
        <TextColumn textOnLeft={textOnLeft} id="contactus">
          <TextContent>
            <Heading>{heading}</Heading>
            {/* {description && <Description>{description}</Description>} */}
            <Form ref={form} onSubmit={sendEmail}>
              <Input 
                id="email"
                type="email" 
                name="email" 
                placeholder="Your Email Address" 
                onChange={(e) => handleChange(e)}
                value={email}
              />
              <Input 
                id="name"
                type="text" 
                name="name"
                placeholder="Full Name"
                onChange={(e) => handleChange(e)}
                value={name}
              />
              <Input
                id="subject"
                type="text" 
                name="subject" 
                placeholder="Subject"
                onChange={(e) => handleChange(e)}
                value={subject}
              />
              <Input 
                id="phone"
                type="phone" 
                name="phone" 
                placeholder="Phone Number"
                onChange={(e)=> handleChange(e)} 
                value={phone}
              />
              <Textarea 
                id="message"
                name="message" 
                placeholder="Your Message Here"
                onChange={(e) => handleChange(e)}
                value={message}
                />
              <SubmitButton type="submit">{submitButtonText}</SubmitButton>
            </Form>
          </TextContent>
        </TextColumn>
      </TwoColumn>
    </Container>
  );
};

export default ContactUs;