import React from "react";
import blogs from "./data";
import {useParams} from "react-router-dom";

export default () => {
    const { id } = useParams();
    const blog = blogs.filter( function (blog) {
      return blog.id === id
    })[0];

  return (
      <article className="post tag-educational-tech tag-javascript tag-developement tag-web-developer single-post">
        <header className="post-header big-title container medium">
            {console.log(blog)}
            <h1 className="post-title">{blog.title}</h1>
            <div className="post-meta">
                <span className="post-meta-item post-meta-date">
                    <time dateTime="2021-12-16">
                        {blog.date}
                    </time>
                </span>
                <span className="post-meta-item post-meta-length">
                    {blog.read_time}
                </span>
                <span className="post-meta-item post-meta-tags">
                    <a className="post-tag post-tag-educational-tech"
                       href="https://www.creative-tim.com/blog/educational-tech/"
                       title="Educational Tech">{blog.category}
                    </a>
                </span>
            </div>
        </header>
        <div className="post-media container large">
          <div className="u-placeholder horizontal">
            <a className="post-image-link"
               href="/blog/educational-tech/what-javascript-void-0-actually-means/">
              <img className="post-image u-object-fit"
                   data-sizes="auto"
                   src={blog.image}
                   alt="What actually means?"
              />
            </a>
          </div>
        </div>
        <div className="post-content kg-canvas u-text-format" dangerouslySetInnerHTML={{__html:blogs[0].content}}>
        </div>

        <div className="container medium">
            {/*<span className="post-meta-item post-meta-tags">*/}
            {/*    <a className="post-tag post-tag-educational-tech" href="/blog/educational-tech/"*/}
            {/*       title="Educational Tech">Educational Tech</a>*/}
            {/*    <a className="post-tag post-tag-javascript" href="/blog/javascript/"*/}
            {/*       title="JavaScript">JavaScript</a>*/}
            {/*    <a className="post-tag post-tag-developement" href="/blog/developement/"*/}
            {/*       title="developement">developement</a>*/}
            {/*    <a className="post-tag post-tag-web-developer" href="/blog/web-developer/"*/}
            {/*       title="Web Developer">Web Developer</a>*/}
            {/*</span>*/}
          <div className="share u-hover-wrapper">
            {/*<a className="share-item share-facebook u-hover-item"*/}
            {/*   href="https://www.facebook.com/sharer.php?u=https://www.creative-tim.com/blog/educational-tech/what-javascript-void-0-actually-means/"*/}
            {/*   target="_blank" rel="noopener"><i className="icon icon-facebook"></i></a>*/}
            {/*<a className="share-item share-twitter u-hover-item"*/}
            {/*   href="https://twitter.com/intent/tweet?url=https://www.creative-tim.com/blog/educational-tech/what-javascript-void-0-actually-means/&text=What%20%60javascript%3Avoid(0)%3B%60%20actually%20means%3F"*/}
            {/*   target="_blank" rel="noopener"><i className="icon icon-twitter"></i></a>*/}
            {/*<a className="share-item share-pinterest u-hover-item"*/}
            {/*   href="https://pinterest.com/pin/create/button/?url=https://www.creative-tim.com/blog/educational-tech/what-javascript-void-0-actually-means/&media=&description=What%20%60javascript%3Avoid(0)%3B%60%20actually%20means%3F"*/}
            {/*   target="_blank" rel="noopener" data-pin-do="none"><i*/}
            {/*    className="icon icon-pinterest"></i></a>*/}
            {/*<a className="share-item share-linkedin u-hover-item"*/}
            {/*   href="https://www.linkedin.com/shareArticle?mini=true&url=https://www.creative-tim.com/blog/educational-tech/what-javascript-void-0-actually-means/&title=What%20%60javascript%3Avoid(0)%3B%60%20actually%20means%3F"*/}
            {/*   target="_blank" rel="noopener"><i className="icon icon-linkedin"></i></a>*/}
            {/*<a className="share-item share-reddit u-hover-item"*/}
            {/*   href="https://reddit.com/submit?url=https://www.creative-tim.com/blog/educational-tech/what-javascript-void-0-actually-means/&title=What%20%60javascript%3Avoid(0)%3B%60%20actually%20means%3F"*/}
            {/*   target="_blank" rel="noopener"><i className="icon icon-reddit"></i></a>*/}
            {/*<a className="share-item share-pocket u-hover-item"*/}
            {/*   href="https://getpocket.com/edit?url=https://www.creative-tim.com/blog/educational-tech/what-javascript-void-0-actually-means/"*/}
            {/*   target="_blank" rel="noopener"><i className="icon icon-pocket"></i></a>*/}
          </div>
        </div>

      </article>
  );
};