import React from "react";
import AnimationRevealPage from "helpers/AnimationRevealPage.js";
import tw from "twin.macro";
import Header, {DesktopNavLinks, LogoLink, NavLink, NavLinks, NavToggle, PrimaryLink} from "components/light.js";
import Footer from "components/footers/MiniCenteredFooter.js";
import MainFeature1 from "components/features/TwoColWithButton.js";
import Features from "components/features/ThreeColSimple.js";
import TeamCardGrid from "components/cards/ProfileThreeColGrid.js";

import SupportIconImage from "images/support-icon.svg";
import ShieldIconImage from "images/shield-icon.svg";
import styled from "styled-components";

const StyledHeader = styled(Header)`
  ${tw`pt-8 max-w-none`}
  ${DesktopNavLinks} ${NavLink}, ${LogoLink} {
    ${tw`text-gray-100 hover:border-gray-300 hover:text-gray-300`}
  }
  ${NavToggle}.closed {
    ${tw`text-gray-100 hover:text-primary-500`}
  }
`;
const NavContainer = styled.div`
  ${tw`relative -mx-8 -mt-8 pb-24 bg-center bg-cover`}
  background-image: url("https://images.unsplash.com/photo-1522071901873-411886a10004?ixlib=rb-1.2.1&auto=format&fit=crop&w=1920&q=80");
`;

const OpacityOverlay = tw.div`z-10 absolute inset-0 bg-primary-500 opacity-25`;

const HeroContainer = tw.div`z-20 relative px-4 sm:px-8 max-w-screen-xl mx-auto`;

const MarginContainer = tw.div`z-20 relative px-4 sm:px-8 max-w-screen-xl mx-auto`;
const Subheading = tw.span`uppercase tracking-wider text-sm`;

export default () => {
  const navLinks = [
    <NavLinks key={1}>
      <NavLink href="/">
        Home
      </NavLink>
      <NavLink href="/#services">
        Services
      </NavLink>
      <NavLink href="/Blogs">
        Blog
      </NavLink>
      <NavLink href="/AboutUs">
        About
      </NavLink>
    </NavLinks>,
    <NavLinks key={2}>
      <PrimaryLink href="/#contactus">
        Contact Us
      </PrimaryLink>
    </NavLinks>
  ];

  return (
    <AnimationRevealPage>

      <NavContainer>
        <OpacityOverlay />
        <HeroContainer>
          <StyledHeader links={navLinks} />
        </HeroContainer>
      </NavContainer>

      <MarginContainer>
        <MainFeature1
          subheading={<Subheading>About Settavers</Subheading>}
          heading="We are a modern IT & Marketing agency."
          description={"We are a start-up specialized in IT services and digital marketing that offers you a multitude of services in order to serve you in complete independence and personalization with a great concern for quality and reliability."}
          buttonRounded={false}
          primaryButtonText="See Portfolio"
          imageSrc="https://res.cloudinary.com/settavers/image/upload/v1641071858/Settavers/about-settavers_eyspiy.jpg"
        />
        {/*<MainFeature1*/}
        {/*  subheading={<Subheading>Our Vision</Subheading>}*/}
        {/*  heading="We aim to disrupt the design space."*/}
        {/*  description={""}*/}
        {/*  buttonRounded={false}*/}
        {/*  primaryButtonText="Contact Us"*/}
        {/*  imageSrc="https://res.cloudinary.com/settavers/image/upload/v1641071858/Settavers/about-settavers_eyspiy.jpg"*/}
        {/*  textOnLeft={false}*/}
        {/*/>*/}
        <Features
          subheading={<Subheading>Our Values</Subheading>}
          heading="We follow these."
          description="Help and support you to exploit the digital technology adapted to your needs to increase your prospects, your revenues by strengthening your digital communication with the help of IT and marketing tools."
          cards={[
            {
              imageSrc: SupportIconImage,
              title: "24/7 Support",
              description: "Can get help and find answers to your questions as soon as they come up."
            },
            {
              imageSrc: ShieldIconImage,
              title: "Strong Teams",
              description: "All team members are responsible for achieving your project objectives."
            },
            {
              imageSrc: ShieldIconImage,
              title: "Customer Satisfaction",
              description: "Our goal is to meet the needs of our customers in order to optimize the relationship."
            },
          ]}
          linkText=""
        />
        <TeamCardGrid
          subheading={<Subheading>Our Team</Subheading>}
        />
        </MarginContainer>
      <Footer />
    </AnimationRevealPage>
  );
};
