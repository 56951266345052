import React from "react";

import BlogContent from "./BlogContent";
import RelatedPost from "./RelatedPost";
import SideBarColmn from './SidebarColumn'
import Header, {DesktopNavLinks, LogoLink, NavLink, NavLinks, NavToggle, PrimaryLink} from "../light";
import styled from "styled-components";
import tw from "twin.macro";
import AnimationRevealPage from "../../helpers/AnimationRevealPage";
import Footer from "../footers/MiniCenteredFooter";

const StyledHeader = styled(Header)`
  ${tw`pt-8 max-w-none`}
  ${DesktopNavLinks} ${NavLink}, ${LogoLink} {
    ${tw`text-gray-100 hover:border-gray-300 hover:text-gray-300`}
  }
  ${NavToggle}.closed {
    ${tw`text-gray-100 hover:text-primary-500`}
  }
`;

const NavContainer = styled.div`
  ${tw`relative -mx-8 -mt-8 pb-24 bg-center bg-cover`}
  background-image: url("https://images.unsplash.com/photo-1522071901873-411886a10004?ixlib=rb-1.2.1&auto=format&fit=crop&w=1920&q=80");
`;

const OpacityOverlay = tw.div`z-10 absolute inset-0 bg-primary-500 opacity-25`;
const HeroContainer = tw.div`z-20 relative px-4 sm:px-8 max-w-screen-xl mx-auto`;


export default () => {

    const navLinks = [
        <NavLinks key={1}>
          <NavLink href="/">
            Home
          </NavLink>
          <NavLink href="/#services">
            Services
          </NavLink>
          <NavLink href="/Blogs">
            Blog
          </NavLink>
          <NavLink href="/AboutUs">
            About
          </NavLink>
        </NavLinks>,
        <NavLinks key={2}>
          <PrimaryLink href="/#contactus">
            Contact Us
          </PrimaryLink>
        </NavLinks>
    ];

    return (
        <AnimationRevealPage>
            <NavContainer>
                <OpacityOverlay />
                    <HeroContainer>
                    <StyledHeader links={navLinks} />
                </HeroContainer>
            </NavContainer>

            <div className="site">
                <div className="site-content container">
                    <div className="row ">
                        <div className="content-column col-xl-9 col-lg-9">
                            <div className="content-area">
                                <main className="site-main">
                                    <BlogContent/>
                                    {/*<RelatedPost/>*/}
                                </main>
                            </div>
                        </div>
                        {/*<SideBarColmn/>*/}
                    </div>
                </div>
            </div>
            <Footer />
        </AnimationRevealPage>
    );
};
