import "tailwindcss/dist/base.css";
import "styles/globalStyles.css";
import "styles/blog/single.css"
import "styles/blog/author.css"
import "styles/blog/elements.css"
import "styles/blog/post.css"
import "styles/blog/featured.css"
import "styles/blog/navigation.css"
import "styles/blog/pagination.css"
import "styles/blog/related.css"
import "styles/blog/share.css"
import "styles/general/button.css"
import "styles/general/basics.css"
import "styles/general/grid.css"
// import "styles/general/normalize.css"
import "styles/misc/kg.css"
import "styles/misc/dark.css"
import "styles/misc/lazyload.css"
import "styles/misc/utilities.css"
import "styles/site/burger.css"
import "styles/site/blockquote.css"
import "styles/site/dimmer.css"
import "styles/site/error.css"
import "styles/site/footer.css"
import "styles/site/header.css"
import "styles/site/layout.css"
import "styles/site/off-canvas.css"
import "styles/site/switch.css"
import "styles/site/table.css"
import "styles/site/term.css"
import "styles/site/widget.css"
import "styles/vendor/owl.css"
import "styles/vendor/mdi.css"
import "styles/vendor/slicknav.css"

import React from "react";

/*
 * This is the entry point component of this project. You can change the below exported default App component to any of
 * the prebuilt landing page components by uncommenting their import and export lines respectively.
 * See one of the landing page components to better understand how to import and render different components (Always
 * make sure if you are building your own page, the root component should be the AnimationRevealPage component. You can
 * disable the animation by using the disabled prop.
 *
 * The App component below is using React router to render the landing page that you see on the live demo website
 * and the component previews.
 *
 */

/* Use AnimationRevealPage as a wrapper component for your pages if you are building a custom one yourself */
// import AnimationRevealPage from "helpers/AnimationRevealPage.js";

/*
 * Hero section is the top most section on the page. It contains the header as well.
 * So you dont need to import headers
 * separately
 */

import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import AgencyLandingPage from "./pages/AgencyLandingPage";
import AboutUsPage from "./pages/AboutUs";
import BlogIndexPage from "./pages/BlogIndex";
import TermOfService from "./pages/TermsOfService";
import PrivacyPolicy from "./pages/PrivacyPolicy";
import MainBlog from "./components/blog/MainBlog";

export default function App() {
  // If you want to disable the animation just use the disabled `prop` like below on your page's component
  // return <AnimationRevealPage disabled>xxxxxxxxxx</AnimationRevealPage>;


  return (
    <Router>
      <Switch>
        <Route path="/BlogContent/:id">
          <MainBlog />
        </Route>
        <Route path="/Blogs">
          <BlogIndexPage />
        </Route>
        <Route path="/AboutUs">
          <AboutUsPage />
        </Route>
        <Route path="/PrivacyPolicy">
          <PrivacyPolicy />
        </Route>
        <Route path="/TermsOfService">
          <TermOfService />
        </Route>
        <Route path="/">
          <AgencyLandingPage />
        </Route>
      </Switch>
    </Router>
  );
}
