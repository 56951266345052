import React from "react";
import tw from "twin.macro";
import styled from "styled-components";
import {Container as ContainerBase } from "components/misc/Layouts.js"
import logo from "../../images/logo_375.svg";
import { ReactComponent as FacebookIcon } from "../../images/facebook-icon.svg";
import { ReactComponent as TwitterIcon } from "../../images/twitter-icon.svg";
import { ReactComponent as YoutubeIcon } from "../../images/youtube-icon.svg";
import { PrimaryButton as PrimaryButtonBase } from "components/misc/Buttons.js";

const Container = tw(ContainerBase)`bg-gray-900 text-gray-100 -mx-8 -mb-8`
const Content = tw.div`max-w-screen-xl mx-auto pt-16 pb-12`;

const Row = tw.div`flex items-center justify-center flex-col px-8`

const LogoContainer = tw.div`flex items-center justify-center md:justify-start`;
const LogoImg = tw.img`w-8`;
const LogoText = tw.h5`ml-2 text-2xl font-black tracking-wider`;

const LinksContainer = tw.div`mt-8 font-medium flex flex-wrap justify-center items-center flex-col sm:flex-row`
const Link = tw.a`border-b-2 border-transparent hocus:text-gray-300 hocus:border-gray-300 pb-1 transition duration-300 mt-2 mx-4`;

const SocialLinksContainer = tw.div`mt-10`;
const SocialLink = styled.a`
  ${tw`cursor-pointer inline-block text-gray-100 hover:text-gray-500 transition duration-300 mx-4`}
  svg {
    ${tw`w-5 h-5`}
  }
`;

const Divider = tw.div`my-8 border-b-2 border-gray-300 w-full`;

const ColumnHeading = tw.h5`uppercase font-bold`;
const SubscribeNewsletterContainer = tw.div`max-w-sm mx-auto text-center`;
const SubscribeText = tw.p`mt-2 text-sm font-medium text-gray-600`;
const SubscribeForm = tw.form`mt-3 text-sm sm:flex max-w-xs sm:max-w-none mx-auto sm:mx-0`;
const Input = tw.input`bg-gray-300 px-6 py-3 rounded sm:rounded-r-none border-2 sm:border-r-0 border-gray-400 hover:border-primary-500 focus:outline-none transition duration-300 w-full`;
const SubscribeButton = tw(PrimaryButtonBase)`mt-4 sm:mt-0 w-full sm:w-auto rounded sm:rounded-l-none px-8 py-3`;

const CopyrightText = tw.p`text-center mt-10 font-medium tracking-wide text-sm text-gray-600`
export default () => {
  return (
    <Container>
      <Content>
        <Row>
          <LogoContainer>
            <LogoImg src={logo} />
            <LogoText>Settavers</LogoText>
          </LogoContainer>
          
          <LinksContainer>
            <Link href="/">Home</Link>
            <Link href="/#services">Services</Link>
            <Link href="/Blogs">Blog</Link>
            <Link href="/AboutUs">About</Link>
            <Link href="/#contactus">Contact Us</Link>
            <Link href="/PrivacyPolicy">Privacy</Link>
            <Link href="/TermsOfService">Terms of Service</Link>
          </LinksContainer>
          
          <SocialLinksContainer>
            <SocialLink href="https://facebook.com">
              <FacebookIcon />
            </SocialLink>
            <SocialLink href="https://twitter.com">
              <TwitterIcon />
            </SocialLink>
            <SocialLink href="https://youtube.com">
              <YoutubeIcon />
            </SocialLink>
          </SocialLinksContainer>

          <Divider/>
          <SubscribeNewsletterContainer>
            <ColumnHeading>Subscribe to our Newsletter</ColumnHeading>
            <SubscribeText>
              We deliver high quality blog posts written by professionals weekly. And we promise no spam.
            </SubscribeText>
            <SubscribeForm method="get" action="#">
              <Input type="email" placeholder="Your Email Address" />
              <SubscribeButton type="submit">Subscribe</SubscribeButton>
            </SubscribeForm>
          </SubscribeNewsletterContainer>

          <CopyrightText>
            &copy; Copyright 2022, Settavers. All Rights Reserved.
          </CopyrightText>
        </Row>
      </Content>
    </Container>
  );
};
