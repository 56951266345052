 export default [
  {
    id: "1",
    title: "What is SEO mean: (Search engine optimization)?",
    image: 'https://res.cloudinary.com/settavers/image/upload/v1640724303/Settavers/blogs/javascriptVoid_sqbw9g.jpg',
    content: "<h2 id=\"when-to - use - javascriptvoid0\">What is SEO mean?</h2>\n" +

        "<p>The English search engine optimization (SEO) refers to what is called natural SEO, these are all techniques " +
        "that can be implemented in order to obtain the best positioning in search engine results."+

        "A website that has a good network and is therefore well referenced is a site that appears in the first results " +
        "when a user makes a request on the internet."+

        "At a time when most companies are developing the online market, SEO is indispensable in any marketing strategy."+
        "This technique makes sense to lead potential clients' interns up to the companies to the services they are " +
        "looking for, so it is essential for a company that wants to be seen and achieve its economic objectives to be " +
        "well referenced.</p>\n" +

        "<h2 id=\"javascript-void-keyword\">What are the techniques of SEO?</h2>\n" +
        "<figure className=\"kg-card kg-image-card\"><img className=\"kg-image\" " +
        "src='https://res.cloudinary.com/settavers/image/upload/v1640724303/Settavers/blogs/artificialInteligence_cevklr.jpg'" +
        " alt=''> </figure>\n" +

        "<p>User experience: concern everything related to the very infrastructure of the website from a technical point of view.</p>\n" +
        "<p>Links: to forge a powerful ecosystem around the website so that search engines can judge its popularity.</p>\n" +
        "<p>The content: Also examine to see if the writing of the site is neat and meets a number of criteria.</p>\n" +

        "<p>No need to explain that having a website is an indispensable solid body for all professionals. It is" +
        " essential to reference your website in order to appear in the first search results when a user makes a request " +
        "on the internet. Well appear on Google is a key issue: we are talking about the natural referencing or SEO. " +
        "However, how to be well positioned in Google? When an Internet user performs a search on Google, using a word " +
        "or expression, Google searches for the content that meets it and then displays the results according to the " +
        "criteria of the natural referencing. It should be noted that natural referencing does not work on the principle" +
        " of “first come, first served.”</p>\n" +

        "<p>Google will examine it according to a list of specific criteria. The objective is therefore to adjust the " +
        "technical elements and optimize the content of a site to enable it to obtain the best positioning in the Google " +
        "results for a given query concerning your activity. SEO concerns all the different elements that make a site " +
        "and that directly or indirectly influence the ranking of it on search engines like Google.</p>\n" +

        "<p>Here are some essentials to be properly referenced on Google:</p>\n" +
        "<p>Creating an effective website: To do this, it must take into account all the elements visible or not by the " +
        "user, respecting the latest technical standards of Google. Moreover, Google establishes a list of theoretical " +
        "criteria to be well ranked among its results. Better to respect them as much as possible!\n" +
        "The creation of a website is therefore a background work that requires technical skills.</p>\n" +

        "<p>This is the role of <a className=\"post-link primary\" href=\"https://settavers.com/\" target=\"_blank\">" +
        "SETTAVERS:</a></p>\n" +

        " <p>Support freelancers in the creation of an effective website. Another essential element to be properly " +
        "referenced on Google: Define specific keywords. It is often said that the content is “king”.\n" +
        "Indeed, Google gives priority to textual or visual content that gives a precise answer to a search.\n" +
        "Always enjoy a good SEO: the structure of the site. The user must be able to quickly find the answer to his " +
        "question. When he walks on your site. Google tests the linking between sections, the internal links between " +
        "pages, and verifies that the texts are unique and relevant. If the elements are not tidy, Google’s robots will" +
        " be lost and give less importance to the site.</p>\n" +
        "<p>Last essential point for the SEO of your site: An incoming link is by definition a link to enter your website.</p>\n" +
        "<p>Practically, this means that a page on the internet has a link to click directly on your site. Google can " +
        "see incoming links to your site. This means that other sites recommend that people visit your site. Moreover, " +
        "for the Google algorithm, if other sites stick to you, your site is interesting. These inbound links help you " +
        "improve natural SEO, especially if they are popular. The more suitable your site has inbound links, the more " +
        "popular you are and the more your site is offered to users.</p>\n" +

        "<p>The essentials: To be well launched on the results pages, your website must be able to be read and valued " +
        "by Internet users, but also by Google. For those whose strategy is to satisfy Google only, they will generally " +
        "be penalized. This is called over SEO optimization.</p>\n" +
        "<p>Yes, Google is a robot, but it interprets your pages and content with great intelligence.\n" +
        "Reporting behind dozens of sites dealing with similar topics. With all these criteria, a good SEO can quickly " +
        "become a real puzzle.</p>\n" +
        "<p>Sometimes Google takes several weeks to recognize your website and gives it the authority it deserves.</p>\n" +
        "<p>At SETTAVERS, an advisor follows your project and advises you to improve the SEO of your site, and adjusts " +
        "certain elements when necessary.</p>\n" +

        "<p>In addition, there are other methods and services available to improve your project.</p>\n" +
        "<p>Do not hesitate to contact us!</p>",
    date: "DEC 16, 2021",
    author: "5 sec ago",
    category: "EDTECH",
    read_time: "3 MIN READ",
    description: "The English search engine optimization (SEO) refers to what is called natural SEO, these are all " +
        "techniques that can be implemented in order to obtain the best positioning in search engine results...",
    featured: true
  },
  {
    id: "2",
    title: "What `javascript:void(0);` actually means?",
    image: 'https://res.cloudinary.com/settavers/image/upload/v1640724303/Settavers/blogs/frontendDeveloper_yvd77c.jpg',
    content: "<p>As a frontend developer or even as a backend developer maybe you have found\n" +
        "            <code>javascript: void(0);</code> written as a value for a href attribute inside\n" +
        "            an anchor tag (<code>&lt;a&gt;</code>) and you wondered what that means.</p>\n" +
        "          <p>Example:</p>\n" +
        "          <pre><code>\n" +
        "&lt;a href=&quot;javascript:void(0);&quot; onclick=&quot;clickFunction()&quot;&gt;\n" +
        "            Click\n" +
        "            &lt;/a&gt;\n" +
        "\n" +
        "</code></pre>\n" +
        "          <p>In this article, we will try to provide an explanation for the above syntax in\n" +
        "            order for you to know when and why it's used. To do that we will have to take it\n" +
        "            element by element.</p>\n" +
        "          <h2 id=\"javascript-void-keyword\">Javascript <code>void</code> keyword</h2>\n" +
        "          <p>As in other programming languages, <code>void</code> keyword from Javascript, is\n" +
        "            an operator that has the role to tell the program NOT to return the result of an\n" +
        "            expression and it returns <code>undefined</code> instead.</p>\n" +
        "          <p>For example, the following expression will return the value of the mathematical\n" +
        "            operation:</p>\n" +
        "          <pre><code>\n" +
        "let sum = 1 + 1;\n" +
        "console.log(sum); // 2\n" +
        "\n" +
        "</code></pre>\n" +
        "          <p>And the next expression will return <code>undefined</code>:</p>\n" +
        "          <pre><code>\n" +
        "let sum = void(1 + 1);\n" +
        "console.log(sum); // undefined\n" +
        "\n" +
        "</code></pre>\n" +
        "          <p>Even though the variable <code>sum</code> is <code>undefined</code>, Javascript\n" +
        "            still executes the expression <code>(1 + 1)</code>:</p>\n" +
        "          <pre><code>\n" +
        "let sum = void console.log(1 + 1); // 2\n" +
        "console.log(sum); // undefined\n" +
        "\n" +
        "</code></pre>\n" +
        "          <p>From the above, we can observe that the code that it is after the\n" +
        "            <code>void</code> operator will always be executed but the return of the\n" +
        "            expression will always be <code>undefined</code>. The role of <code>0</code>\n" +
        "            inside the void operator is simply just the one of a placeholder.</p>\n" +
        "          <h2 id=\"use-javascriptvoid0-as-the-value-of-href\">Use\n" +
        "            <code>javascript:void(0);</code> as the value of href</h2>\n" +
        "          <p>The <code>javascript:</code> part is a possible value for the <code>href</code>\n" +
        "            attribute. This value tells your browser that the next text is a Javascript code\n" +
        "            and it should be treated accordingly. There are also other possible values for\n" +
        "            the <code>href</code> attribute like mailto:, file:, tel:, sms: etc.</p>\n" +
        "          <figure className=\"kg-card kg-image-card\">\n" +
        "              <img\n" +
        "              src=\"https://www.creative-tim.com./assets/images/2021/12/coder.gif\"\n" +
        "              className=\"kg-image\" alt=\"dsds\" loading=\"lazy\" width=\"480\" height=\"268\"\n" +
        "              /></figure>\n" +
        "          <h2 id=\"what-is-javascriptvoid0\">What is <code>javascript:void(0);</code>?</h2>\n" +
        "          <p>By using <code>javascript:void(0)</code>, the <code>&lt;a&gt;</code> tag will not\n" +
        "            send you to other web address. It will also not refresh the page as links\n" +
        "            usually do when you don't specify a value for the href attribute.</p>\n" +
        "          <p>If you are wondering why we don't just remove the <code>href</code> attribute,\n" +
        "            the answer is that by removing the <code>href</code> attribute we will also\n" +
        "            remove the link appearance of the <code>&lt;a&gt;</code> element. That means\n" +
        "            that the cursor will not change when hovering the <code>&lt;a&gt;</code> tag.\n" +
        "            The cursor will act like it is on normal text.</p>\n" +
        "          <p>In other words, by adding <code>javascript:void(0)</code> as the value of href\n" +
        "            attribute we will create a link that does nothing.</p>\n" +
        "          <p>The example link from the beginning of this article will run the\n" +
        "            <code>clickFunction</code> function when the tag is clicked, but without\n" +
        "            refreshing the page or sending you to a different page.</p>\n" +
        "          <p><a className=\"post-link primary\"\n" +
        "                href=\"https://www.creative-tim.com/blog/courses/javascript-courses-learn-web-development\"\n" +
        "                target=\"_blank\">You may be interested: 15 JavaScript Courses - Learn Web\n" +
        "            Development, HTML, and CSS</a></p>\n" +
        "          <h2 id=\"when-to-use-javascriptvoid0\">When to use <code>javascript:void(0);</code>?\n" +
        "          </h2>\n" +
        "          <p>Thankfully, with all the updates made in Javascript, you will never have to use\n" +
        "            it as there are better alternatives to it.</p>\n" +
        "          <p>In order to prevent the default behavior of a <code>&lt;a&gt;</code> tag, it's\n" +
        "            generally recommended to use the <code>&lt;button&gt;</code> tag for buttons and\n" +
        "            the <code>&lt;a&gt;</code> tag for links. Also, if you need or want to change\n" +
        "            the cursor, it's recommended to use CSS instead of changing the tag to\n" +
        "            <code>&lt;a&gt;</code>.</p>\n" +
        "          <p>In conclusion, we hope that now you have a better understanding of what\n" +
        "            <code>javascript:void(0);</code> is. Since it does nothing that you can't do\n" +
        "            with only HTML/CSS, the syntax presented at the beginning is not so common these\n" +
        "            days. Use this piece of information only to know what role it has when you see\n" +
        "            it somewhere, but keep your code clean and follow our advice on best practices.\n" +
        "          </p>",
    date: "DEC 16, 2021",
    author: "Sanaa Dakir",
    category: "EDUCATIONAL TECH",
    read_time: "3 MIN READ",
    description: "As a frontend developer or even as a backend developer maybe you have found javascript: void(0); written as a value for a href attribute inside an anchor tag (<a>) and you wondered ...",
  },
  {
    id: "3",
    title: "What `javascript:void(0);` actually means?",
    image: 'https://res.cloudinary.com/settavers/image/upload/v1640724303/Settavers/blogs/how-to-become-backend-developer_ltvylp.jpg',
    content: "<p>As a frontend developer or even as a backend developer maybe you have found\n" +
        "            <code>javascript: void(0);</code> written as a value for a href attribute inside\n" +
        "            an anchor tag (<code>&lt;a&gt;</code>) and you wondered what that means.</p>\n" +
        "          <p>Example:</p>\n" +
        "          <pre><code>\n" +
        "&lt;a href=&quot;javascript:void(0);&quot; onclick=&quot;clickFunction()&quot;&gt;\n" +
        "            Click\n" +
        "            &lt;/a&gt;\n" +
        "\n" +
        "</code></pre>\n" +
        "          <p>In this article, we will try to provide an explanation for the above syntax in\n" +
        "            order for you to know when and why it's used. To do that we will have to take it\n" +
        "            element by element.</p>\n" +
        "          <h2 id=\"javascript-void-keyword\">Javascript <code>void</code> keyword</h2>\n" +
        "          <p>As in other programming languages, <code>void</code> keyword from Javascript, is\n" +
        "            an operator that has the role to tell the program NOT to return the result of an\n" +
        "            expression and it returns <code>undefined</code> instead.</p>\n" +
        "          <p>For example, the following expression will return the value of the mathematical\n" +
        "            operation:</p>\n" +
        "          <pre><code>\n" +
        "let sum = 1 + 1;\n" +
        "console.log(sum); // 2\n" +
        "\n" +
        "</code></pre>\n" +
        "          <p>And the next expression will return <code>undefined</code>:</p>\n" +
        "          <pre><code>\n" +
        "let sum = void(1 + 1);\n" +
        "console.log(sum); // undefined\n" +
        "\n" +
        "</code></pre>\n" +
        "          <p>Even though the variable <code>sum</code> is <code>undefined</code>, Javascript\n" +
        "            still executes the expression <code>(1 + 1)</code>:</p>\n" +
        "          <pre><code>\n" +
        "let sum = void console.log(1 + 1); // 2\n" +
        "console.log(sum); // undefined\n" +
        "\n" +
        "</code></pre>\n" +
        "          <p>From the above, we can observe that the code that it is after the\n" +
        "            <code>void</code> operator will always be executed but the return of the\n" +
        "            expression will always be <code>undefined</code>. The role of <code>0</code>\n" +
        "            inside the void operator is simply just the one of a placeholder.</p>\n" +
        "          <h2 id=\"use-javascriptvoid0-as-the-value-of-href\">Use\n" +
        "            <code>javascript:void(0);</code> as the value of href</h2>\n" +
        "          <p>The <code>javascript:</code> part is a possible value for the <code>href</code>\n" +
        "            attribute. This value tells your browser that the next text is a Javascript code\n" +
        "            and it should be treated accordingly. There are also other possible values for\n" +
        "            the <code>href</code> attribute like mailto:, file:, tel:, sms: etc.</p>\n" +
        "          <figure className=\"kg-card kg-image-card\">\n" +
        "              <img\n" +
        "              src=\"https://www.creative-tim.com./assets/images/2021/12/coder.gif\"\n" +
        "              className=\"kg-image\" alt=\"dsds\" loading=\"lazy\" width=\"480\" height=\"268\"\n" +
        "              /></figure>\n" +
        "          <h2 id=\"what-is-javascriptvoid0\">What is <code>javascript:void(0);</code>?</h2>\n" +
        "          <p>By using <code>javascript:void(0)</code>, the <code>&lt;a&gt;</code> tag will not\n" +
        "            send you to other web address. It will also not refresh the page as links\n" +
        "            usually do when you don't specify a value for the href attribute.</p>\n" +
        "          <p>If you are wondering why we don't just remove the <code>href</code> attribute,\n" +
        "            the answer is that by removing the <code>href</code> attribute we will also\n" +
        "            remove the link appearance of the <code>&lt;a&gt;</code> element. That means\n" +
        "            that the cursor will not change when hovering the <code>&lt;a&gt;</code> tag.\n" +
        "            The cursor will act like it is on normal text.</p>\n" +
        "          <p>In other words, by adding <code>javascript:void(0)</code> as the value of href\n" +
        "            attribute we will create a link that does nothing.</p>\n" +
        "          <p>The example link from the beginning of this article will run the\n" +
        "            <code>clickFunction</code> function when the tag is clicked, but without\n" +
        "            refreshing the page or sending you to a different page.</p>\n" +
        "          <p><a className=\"post-link primary\"\n" +
        "                href=\"https://www.creative-tim.com/blog/courses/javascript-courses-learn-web-development\"\n" +
        "                target=\"_blank\">You may be interested: 15 JavaScript Courses - Learn Web\n" +
        "            Development, HTML, and CSS</a></p>\n" +
        "          <h2 id=\"when-to-use-javascriptvoid0\">When to use <code>javascript:void(0);</code>?\n" +
        "          </h2>\n" +
        "          <p>Thankfully, with all the updates made in Javascript, you will never have to use\n" +
        "            it as there are better alternatives to it.</p>\n" +
        "          <p>In order to prevent the default behavior of a <code>&lt;a&gt;</code> tag, it's\n" +
        "            generally recommended to use the <code>&lt;button&gt;</code> tag for buttons and\n" +
        "            the <code>&lt;a&gt;</code> tag for links. Also, if you need or want to change\n" +
        "            the cursor, it's recommended to use CSS instead of changing the tag to\n" +
        "            <code>&lt;a&gt;</code>.</p>\n" +
        "          <p>In conclusion, we hope that now you have a better understanding of what\n" +
        "            <code>javascript:void(0);</code> is. Since it does nothing that you can't do\n" +
        "            with only HTML/CSS, the syntax presented at the beginning is not so common these\n" +
        "            days. Use this piece of information only to know what role it has when you see\n" +
        "            it somewhere, but keep your code clean and follow our advice on best practices.\n" +
        "          </p>",
    date: "DEC 16, 2021",
    author: "Sanaa Dakir",
    category: "EDTECH",
    read_time: "3 MIN READ",
    description: "As a frontend developer or even as a backend developer maybe you have found javascript: void(0); written as a value for a href attribute inside an anchor tag (<a>) and you wondered ...",
  },
];
